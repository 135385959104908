.rwa__datatable {
  border: rem(1) solid variableValues($neutral, '20');
  border-radius: rem(4);
  min-height: 0;
  max-height: 100%;
  position: relative;
  &.fit-page {
    max-height: 91%;
  }
  .custom-loader {
    position: absolute;
    top: calc(50% + 35px);
    transform: translateY(-50%);
    font-weight: 700;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .table-head-center {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(8);
  }
  p-table {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
    overflow: auto;
    td {
      .p-inputtext {
        width: 100%;
      }
    }
  }
  .p-datatable {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    &-loading-overlay {
      top: 50%;
      transform: translateY(-50%);
      display: none !important;
    }

    &-scrollable {
      .p-datatable-wrapper {
        &::-webkit-scrollbar {
          height: 14px;
        }
      }
    }
    &-wrapper {
      border-radius: rem(4);
    }
    &-thead {
      z-index: 2 !important;
      tr {
        th {
          //S
          // background-color: white;
          // justify-content: center;
          // text-align: left;
          border: 0;
          font-size: rem(14);
          height: rem(48);
          min-width: rem(200);
          padding: rem(6) rem(16);
          line-height: rem(16);
          vertical-align: middle;
          gap: rem(8);
          @extend .fw-500;
          &:first-child {
            border-start-start-radius: rem(6);
          }
          &:last-child {
            border-start-end-radius: rem(6);
          }
          p-sorticon {
            border-radius: rem(4);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: rem(5);
            height: rem(32);
            width: rem(32);
            i {
              font-size: rem(14);
              margin: 0 !important;
            }
          }
          &.rw__filter-active {
            border-bottom-color: variableValues($neutral, '20');
            p-sorticon {
              background-color: variableValues($purple, '20') !important;
              i {
                color: variableValues($purple, '60') !important;
              }
            }
          }
          &.rw__customize-head {
            background-color: variableValues($neutral, '10');
            color: variableValues($neutral, '70');
          }
        }
      }
    }
    &-tbody {
      tr {
        // &:hover{
        //     td {
        //         background-color: variableValues($neutral, "10");
        //     }
        // }
        // &.selected-row {
        //     td {
        //         background-color: variableValues($neutral, "10");
        //     }
        // }
        &.new {
          border-color: variableValues($purple, '20') !important;
          td {
            background-color: rgba(variableValues($purple, '10'), 0.3);
            &:first-child {
              &:before {
                background-color: variableValues($purple, '50');
                content: '';
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                width: rem(4);
              }
            }
          }
        }
        &:not(:last-child) {
          border-bottom: rem(1) solid variableValues($neutral, '20');
        }
        td {
          background-color: white;
          border-top: rem(1) solid variableValues($neutral, '20');
          border-bottom: 0;
          font-size: rem(14);
          min-height: rem(72);
          max-height: rem(84);
          padding: rem(12) rem(16);
          line-height: rem(20);
          word-break: break-word;
          white-space: normal !important;
          .rw__data-val {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .record-sub-text {
            color: variableValues($neutral, '70');
            font-size: rem(12);
          }
        }
      }
    }
    .leftFrozenColumn {
      box-shadow: rem(4) 0 rem(6) rem(-1) rgba(black, 0.1);
      flex: auto;
      position: sticky;
      left: 0;
      min-width: auto;
      z-index: 1;
    }
    .rightFrozenColumn {
      box-shadow: rem(-4) 0 rem(6) rem(-1) rgba(black, 0.1);
      display: flex;
      justify-content: center;
      flex: auto;
      position: sticky;
      right: 0;
      min-width: auto;
      .p-button {
        text-align: left;
        &:active,
        &:focus {
          background-color: variableValues($purple, '20') !important;
          color: variableValues($purple, '90') !important;
        }
      }
    }
  }
  &-paginator {
    border: rem(1) solid variableValues($neutral, '20');
    border-bottom-left-radius: rem(4);
    border-bottom-right-radius: rem(4);
    background-color: white;
    min-height: rem(56);
    select {
      height: rem(36);
      min-width: rem(50);
    }
    .p-paginator {
      padding: 0;
      &-pages .p-paginator-page,
      .p-paginator-element {
        border-radius: 50%;
        color: variableValues($neutral, '70');
        min-width: rem(36);
        height: rem(36);
        &.p-highlight {
          background-color: variableValues($purple, '20');
          color: variableValues($purple, '70');
        }
      }
      &-pages .p-paginator-page {
        font-size: rem(14);
      }
      &-element {
        &.p-disabled {
          color: variableValues($neutral, '50');
          opacity: 1;
        }
        .p-paginator-icon {
          font-size: rem(18);
        }
      }
    }
    span:not(.p-dropdown span) {
      color: variableValues($neutral, '70');
    }
  }
  &-resize {
    .p-datatable {
      &-thead {
        th {
          min-width: auto !important;
          &.p-resizable-column {
            .p-column-resizer {
              border-left: rem(1) solid variableValues($neutral, '20');
            }
          }
        }
      }
      &-tbody {
        td {
          min-width: auto !important;
        }
      }
    }
  }
  &-box {
    background-color: white;
    overflow: hidden;
    &-row {
      border-top: rem(1) solid variableValues($neutral, '10');
      padding: rem(12);
    }
    &-head {
      padding: rem(4) rem(4) rem(4) rem(12);
    }
    &.selected {
      background-color: variableValues($purple, '10');
      .rw__datatable-v2-box-row {
        border-top-color: variableValues($neutral, '20') !important;
      }
    }
    &.new {
      background-color: rgba(variableValues($purple, '10'), 0.3);
      .rw__datatable-v2-box-head {
        position: relative;
        &:before {
          background-color: variableValues($purple, '50');
          content: '';
          height: rem(4);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
        }
      }
      .rw__datatable-v2-box-row {
        border-top-color: variableValues($purple, '20') !important;
      }
    }
  }
}

.rwa__datatable-transparent {
  .p-datatable {
    &-thead {
      th {
        background-color: transparent;
        border: 0;
        padding-top: 0;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
    &-tbody {
      tr {
        background-color: transparent;
        td {
          border: 0;
          padding-top: 0;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.rtl {
  .rwa__datatable {
    .p-datatable {
      .leftFrozenColumn {
        box-shadow: rem(-4) 0 rem(6) rem(-1) rgba(black, 0.1) !important;
        left: auto !important;
        right: 0 !important;
      }
      .rightFrozenColumn {
        box-shadow: rem(4) 0 rem(6) rem(-1) rgba(black, 0.1) !important;
        right: auto !important;
        left: 0 !important;
      }
    }
  }
}
