@use 'sass:math';
@use 'sass:map';

@mixin mq($breakpoint, $type: min) {
  @if map_has_key($breakpoints, $breakpoint) {
    $width: map_get($breakpoints, $breakpoint);
    @if $type==max {
      $width: $width - 1px;
    }
    @media (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin mq-only($min-width, $max-width) {
  @if map_has_key($breakpoints, $min-width) and map_has_key($breakpoints, $max-width) {
    $min-width: map_get($breakpoints, $min-width);
    $max-width: map_get($breakpoints, $max-width) - 1px;
    @media (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  }
}

@function rem($pixels) {
  @return math.div($pixels, 16) * 1rem;
}

@function variableValues($variable, $value) {
  @return map.get($variable, $value);
}

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}
