//===========================================
// -Helper Classes start
//===========================================
.extra-gutter {
  margin-right: -1rem;
  margin-left: -1rem;
  margin-top: -1rem;

  & > .col,
  & > [class*='col-'] {
    padding: 1rem;
  }
}

.rw-datatable {
  .p-inputtext {
    min-width: 290px;
  }

  .p-datatable {
    overflow: hidden;
    border-radius: 6px;
  }
}

.p-element {
  > svg {
    display: block;
  }
}

.title-with-icon-box {
  display: flex;
  align-items: flex-start;
  gap: rem(variableValues($sizes, '11'));

  .title {
    min-width: 200px;

    .headline {
      margin-bottom: 4px;
    }

    .text {
      color: #738080;
      font-size: rem(variableValues($sizes, '10'));
      font-weight: variableValues($weights, '700');
    }
  }
}

.icon-box {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #dfd5e5;
  color: #9572a9;
  font-size: rem(variableValues($sizes, '6'));
  @include flex-center();
}

.card-info:not(:last-child) {
  margin-bottom: 20px;
}

.card-info-label {
  color: #738080;
  font-size: rem(variableValues($sizes, '9'));
  font-weight: variableValues($weights, '400');
  letter-spacing: 0.1px;
  margin-bottom: 8px;
}

.card-info-text {
  color: $black;
  font-size: rem(variableValues($sizes, '8'));
  font-weight: variableValues($weights, '400');
  letter-spacing: 0.5px;

  > a {
    color: inherit;
    text-decoration: none;
  }
}

@each $name, $value in $widths {
  .w-#{$name} {
    width: $value;
  }
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.has-sticky-action {
  padding-bottom: 100px;
}

.sticky-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 16px 32px;
  border: 1px solid variableValues($neutral, '20');
  border-radius: variableValues($border-radius, '1');
  box-shadow: 0px -2px 12px 0px rgba($black, 0.1);

  p {
    color: variableValues($neutral, '90');

    small {
      color: variableValues($neutral, '70');
    }
  }
}

.bg-neutral-10 {
  background-color: variableValues($neutral, '10') !important;
}

.bg-purple-10 {
  background-color: variableValues($purple, '10') !important;
}

.bg-yellow-70 {
  background-color: variableValues($yellow, '70') !important;
}

.bg-neutral-10 {
  background-color: variableValues($neutral, '10') !important;
}

.bg-neutral-20 {
  background-color: variableValues($neutral, '20') !important;
}

.border-neutral-20 {
  border-color: variableValues($neutral, '20') !important;
}

.border-purple-10 {
  border-color: variableValues($purple, '10') !important;
}

.text-neutral-50 {
  color: variableValues($neutral, '50') !important;
}

.text-neutral-70 {
  color: variableValues($neutral, '70') !important;
}

.text-neutral-90 {
  color: variableValues($neutral, '90') !important;
}

.textarea-resize-none {
  resize: none;
}

//====== markdown
.custom-markdown h2 {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #0d2222 !important;
}

.custom-markdown p {
  font-size: 16px;
  font-weight: 400;
  color: #5c7070 !important;
}

.custom-markdown ul {
  list-style: revert;
  margin: revert;
  padding: revert;
}

//===========

//===========================================
// -Helper Classes end
//===========================================
