p-dialog {
  &[ng-reflect-show-header='false'] {
    .p-dialog-content {
      border-top-right-radius: rem(4);
      border-top-left-radius: rem(4);
    }
  }
  .p-dialog {
    &-header {
      border: 0;
      padding: rem(20);
      padding-bottom: 0;
      label,
      .p-dialog-title {
        color: variableValues($neutral, '90');
        font-size: rem(20);
        font-weight: 500;
      }
      span {
        color: variableValues($neutral, '70');
        font-size: rem(14);
        font-weight: 400;
        margin-top: rem(8);
      }
    }
    &-content {
      padding: rem(0) rem(16);
    }
    &-footer {
      border: 0;
      padding: rem(16);
    }
  }
}
