@import '../../scss/helpers/variables';
@import '../../scss/helpers/mixins';

.call-analysis-list-page {
  .filter-clear-button-main {
    padding: 10.6px 21px;
  }
}

.call-analysis-filter-sidebar {
  width: 25rem;

  .p-sidebar-header {
    padding-bottom: 0;
  }

  .p-accordion .p-accordiontab {
    margin-bottom: 16px !important;
  }

  .p-accordion .p-accordiontab:last-child {
    margin-bottom: 0 !important;
  }

  .p-selectbutton {
    div.p-element.p-component {
      margin-bottom: 5px;
    }
  }
}

.call-analysis-list-p-badge-contrast {
  color: $primary-text;
  background-color: $primary;
}

.filter-call-analysis-date-range {
  display: contents;

  span.p-calendar {
    display: contents;
  }
}

.filter-invalid-time-range {
  input {
    border: 2px solid variableValues($red, '50');
  }
}
